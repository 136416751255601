<template>
  <b-input-group>
    <b-input-group-prepend>
      <b-button variant="outline-dark" class="py-0" size="sm" @click="valueChange(Number(value) - step)">
        <feather-icon icon="MinusIcon" size="20" />
      </b-button>
    </b-input-group-prepend>

    <b-form-input :id="id" :size="size" v-model="currentValue" :min="min" :max="max" :step="step" type="number"
      class="border-secondary text-center" number @blur="valueChange(currentValue)" />

    <b-input-group-append>
      <b-button variant="outline-dark" class="py-0" size="sm" @click="valueChange(Number(value) + step)">
        <feather-icon icon="PlusIcon" size="20" />
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import { BIcon, BIconDash, BIconPlus, BFormInput, BInputGroupPrepend, BInputGroupAppend, BButton, BInputGroup } from 'bootstrap-vue'

export default {

  components: {
    BIcon,
    BButton,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BInputGroupAppend,
    /* eslint-disable vue/no-unused-components */
    BIconDash,
    BIconPlus
  },
  data() {
    return {
      currentValue: null
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },

    size: {
      type: String,
      required: false,
      default: 'md',
      validator: function (value) {
        return ['sm', 'md', 'lg'].includes(value)
      }
    },
    value: {
      type: [Number, String],
      required: true,
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    step: {
      type: Number,
      required: false,
      default: 1
    }
  },
  watch: {
    currentValue: {
      handler(val) {
        console.log(val);
        // this.valueChange(val)
      }
    }
  },
  mounted() {
    this.currentValue = this.value
  },
  methods: {
    valueChange (newValue) {
      console.log(newValue >= this.min && newValue <= this.max);
      if (newValue >= this.min && newValue <= this.max) {
        this.$emit('input', Number(newValue).toFixed(2))
        this.currentValue = Number(newValue).toFixed(2)
      } else {
        this.$emit('input', this.min)
        this.currentValue = this.min
      } 
    }
  }
}
</script>

<style scoped>
/* Remove up and down arrows inside number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>